import { BrowserRouter } from 'react-router-dom';
import logo from './logo.svg';
import { ToastContainer } from 'react-toastify';
import Navigations from './Routers/Navigations';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Navigations />
        <ToastContainer />
      </BrowserRouter>
    </div>
  );
}

export default App;
