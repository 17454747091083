import React, { useState } from 'react';
import { Wheel } from 'react-custom-roulette';

import Spin from "../../src/assets/images/Spin.svg";
import CustomPointerImage from "../../src/assets/images/pointer.svg";

const data = [
    { option: '0' },
    { option: '1' },
    { option: '2' },
    { option: '3' },
    { option: '4' },
    { option: '5' },
    { option: '6' },
    { option: '7' },
    { option: '8' },
    { option: '9' },
    { option: '10' },
    { option: '11' },
    { option: '12' },
    { option: '13' },
    { option: '14' },
    { option: '15' },
    { option: '16' },
    { option: '17' },
    { option: '18' },
    { option: '19' },
    { option: '20' },
    { option: '21' },
    { option: '22' },
];

const SpinWheel = ({ mustSpin, setMustSpin }) => {
    const [prizeNumber, setPrizeNumber] = useState(0);
    const slices = data.length;
    const pointerOffset = 3; // Adjust this based on the pointer position

    const handleSpinClick = () => {
        const newPrizeNumber = Math.floor(Math.random() * slices);
        setPrizeNumber(newPrizeNumber);
        setMustSpin(true);
    };

    const adjustedPrizeNumber = (prizeNumber - pointerOffset + slices) % slices;

    return (
        <>
            <div className="wheel-container">
                <div className="gradient-border">
                    <Wheel
                        mustStartSpinning={mustSpin}
                        prizeNumber={prizeNumber}
                        data={data}
                        spinDuration={[0.5]}
                        outerBorderWidth={[0]}
                        innerBorderColor={["#f2f2f2"]}
                        radiusLineColor={["transparent"]}
                        radiusLineWidth={[1]}
                        textColors={["#f5f5f5"]}
                        textDistance={80}
                        fontSize={[18]}
                        fontWeight={600}
                        backgroundColors={[
                            "#3f297e", "#175fa9", "#169ed8", "#239b63", "#64b031",
                            "#efe61f", "#f7a416", "#e6471d", "#dc0936", "#e5177b",
                            "#be1180", "#871f7f"
                        ]}
                        onStopSpinning={() => {
                            setMustSpin(false);
                        }}
                        pointerProps={{ src: CustomPointerImage }} // Custom pointer image
                    />
                    <button disabled={mustSpin} className="button roulette-button" onClick={handleSpinClick}>
                        <img src={Spin} alt="spin" />
                    </button>
                </div>
                <div className="dots-container">
                    {Array.from({ length: 8 }).map((_, i) => (
                        <div key={i} className={`dot dot-${i}`} />
                    ))}
                </div>
            </div>
        </>
    );
};


export default SpinWheel;