import React, { useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import SpinWheel from "./SpinWheel";

const Dashboard = () => {
    const [mustSpin, setMustSpin] = useState(false);

    return (
        <>
            <div className="spin-wheel container mt-5 mb-5">
                <div className="heading mb-5 d-flex flex-column justify-content-center align-items-center w-100">
                    <h1>Spin to Win Exciting Prizes!</h1>
                    <h1>Try Your Luck and Spin the Wheel</h1>
                </div>
                <Row className="row-spinner p-5 display-flex align-items-center">
                    <Col md={12} lg={6} className="content-column">
                        <div className="content">
                            <h1 className="mb-4">Spin The Wheel</h1>
                            <p className="mb-4">Welcome to our exclusive Spin to Win game! Simply click on the wheel to spin and stand a chance to win amazing discounts, free products, and other fantastic prizes. Each spin gives you an opportunity to score big and make your shopping experience even more rewarding.</p>
                            <h3 className="mb-2">Prizes Include:</h3>
                            <ul className="mb-5">
                                <li>Exclusive discounts up to 50%</li>
                                <li>Free shipping on your next order</li>
                                <li>Complimentary products</li>
                                <li>And many more surprises!</li>
                            </ul>
                            <Button onClick={() => setMustSpin(true)}>Spin Now</Button>
                        </div>
                    </Col>
                    <Col md={{ span: 12, offset: 0 }} lg={{ span: 5, offset: 1 }} className="spinner-column">
                        <SpinWheel mustSpin={mustSpin} setMustSpin={setMustSpin} />
                    </Col>
                </Row>

            </div>
        </>
    )
}
export default Dashboard