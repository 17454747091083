import React from "react";
import { Route, Routes } from "react-router-dom";
import Dashboard from "../pages/Dashboard";


const Navigations = () => {
    return (
        <>
            <Routes>
                <Route path="/" element={<Dashboard />} ></Route>
            </Routes>
        </>
    )
}

export default Navigations